<template>
  <div>
    <b-row>
      <b-col cols="10" class="login-card text-center p-3 shadow rounded">
        <div>
          <img
            class="my-5 py-3 login-logo"
            src="../../assets/images/d-dot-logo.svg"
            alt
          />
          <div class="form-group">
            <input
              ref="usernameInput"
              v-model="username"
              type="text"
              class="form-control rounded-pill"
              placeholder="Username"
            />
          </div>
          <div class="form-group">
            <input
              v-model="password"
              type="password"
              class="form-control rounded-pill"
              placeholder="Password"
              @keyup.enter="login()"
            />
          </div>
          <button
            @click="login()"
            class="btn w-100 rounded-pill bg-color-primary text-white"
            :disabled="isDisable"
          >
            Login
          </button>
          <div class="text-danger pt-2">{{ message }}</div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      message: "",
      brandId: null,
      isDisable: false
    };
  },
  created() {
    this.$store.dispatch("setBrand", null);
  },
  mounted() {
    this.$refs.usernameInput.focus();
  },
  methods: {
    async login() {
      this.message = "";
      if (this.validate()) {
        this.isDisable = true;
        await this.$axios
          .post(this.$baseUrl + "/api/Authenticate", {
            username: this.username,
            password: this.password
          })
          .then(async response => {
            if (response.data.result === 1) {
              await this.$axios
                .post(this.$chatBaseUrl + "/user/update-token", {
                  email: this.username,
                  password: this.password,
                  token: response.data.detail.token
                })
                .then(response2 => {
                  if (response2.data.result === 1) {
                    var timestamp = new Date().getTime();
                    this.$cookies.set(
                      "d-order-session-token",
                      response.data.detail.token
                    );
                    this.$headersChat.Authorization = response.data.detail.token
                      ? `Bearer ${response.data.detail.token}`
                      : "null";
                    this.$cookies.set("d-order-username", this.username);
                    this.$cookies.set("d-order-timestamp", timestamp);
                    this.$store.dispatch("setTimestamp", timestamp);
                    this.getAllBrand();
                    this.isDisable = false;
                  } else {
                    this.isDisable = false;
                    this.message = response2.data.message;
                  }
                });
            } else {
              this.isDisable = false;
              this.message = response.data.message;
            }
          });
      } else {
        this.message = "กรุณากรอกข้อมูล";
      }
    },
    async getAllBrand() {
      await this.$axios.get(this.$baseUrl + "/api/brand").then(response => {
        if (response.data.result === 1) {
          this.$store.dispatch("setBrand", response.data.detail[0].id);
          this.$router.push({
            path: "/",
            query: {
              brandID: response.data.detail[0].id,
              isMobile: this.$route.query.isMobile == "true" ? "true" : ""
            }
          });
        }
      });
    },
    validate() {
      if (this.username === "") return false;
      if (this.password === "") return false;
      return true;
    }
  }
};
</script>

<style>
.login-card {
  width: 360px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.login-logo {
  width: 80%;
  height: 100%;
}
</style>
